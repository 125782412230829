<template>
  <div class="managingPeople hp100 bbox" v-loading="loading">
    <!-- 搜索-->
    <el-row :gutter="20" type="flex" justify="space-between">
      <el-col>
        <!-- <el-button plain class="add-column-btn bbox" @click="gotoDetail('')" type="primary">
                       <i class="el-icon-plus fw900 f16"></i> 新增</el-button> -->
      </el-col>
      <!-- <el-col style="width: 350px; margin-top: 15px">
        <div class="flex align-center justify-end header-search-box">
          <div class="flex align-center justify-end header-search-box">
            <el-date-picker
              v-model="payInfoTime"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="请选择开始日期"
              end-placeholder="请选择结束日期"
            >
            </el-date-picker>
            <el-button
              slot="append"
              icon="el-icon-search"
              class="textblue searchBtn"
              @click="search()"
            ></el-button>
          </div>
        </div>
      </el-col> -->
    </el-row>
    <!-- 数据报表 -->
    <el-row :gutter="20" style="border: 1px solid #ebeef5; margin: 15px 0; border-radius: 5px" class="data-box">
      <el-col style="padding: 15px 15px" :span="6" :offset="0">停车点数量：<span class="data">{{ data.electronicFencesCount ?
        data.electronicFencesCount : 0 }}</span></el-col>
      <el-col style="padding: 15px 15px" :span="6" :offset="0">管理员：<span class="data">{{ data.managesCount ?
        data.managesCount : 0 }}</span></el-col>
      <el-col style="padding: 15px 15px" :span="6" :offset="0">运营商数量：<span class="data">{{ data.operatorCount ?
        data.operatorCount : 0 }}</span></el-col>
      <!-- <el-col style="padding: 15px 15px" :span="6" :offset="0"
        >会员数：<span class="data">{{ data.memberCount }}</span></el-col
      > -->
      <el-col style="padding: 15px 15px" :span="6" :offset="0">总用户数：<span class="data">{{ data.memberCount ?
        data.memberCount : 0 }}</span></el-col>
      <el-col style="padding: 15px 15px" :span="6" :offset="0">今日新增用户数：<span class="data">{{ data.todayCount ?
        data.todayCount : 0 }}</span></el-col>
      <el-col style="padding: 15px 15px" :span="6" :offset="0">总订单数：<span class="data">{{ data.totalOrderCount ?
        data.totalOrderCount : 0 }}</span></el-col>
      <el-col style="padding: 15px 15px" :span="6" :offset="0">车辆总数：<span class="data">{{ data.totalDeviceCount ?
        data.totalDeviceCount : 0 }}</span></el-col>
      <el-col style="padding: 15px 15px" :span="6" :offset="0">使用中车辆数：<span class="data">{{ data.using ?
        data.using : 0 }}</span></el-col>
      <el-col style="padding: 15px 15px" :span="6" :offset="0">维修中车辆数：<span class="data">{{ data.fixing ?
        data.fixing : 0 }}</span></el-col>
      <el-col style="padding: 15px 15px" :span="6" :offset="0">可使用车辆数：<span class="data">{{ data.usable ?
        data.usable : 0 }}</span></el-col>
      <!-- <el-col style="padding: 15px 15px" :span="6" :offset="0"
        >会员充值次数：<span class="data">{{ data.memberDepositCount }}</span></el-col
      >
      <el-col style="padding: 15px 15px" :span="6" :offset="0"
        >会员充值总额：<span class="data">{{ data.memberDepositMoney }}</span></el-col
      > -->
      <el-col style="padding: 15px 15px" :span="6" :offset="0">今日入账：<span class="data">{{ data.todayIncome ?
        data.todayIncome : 0 }} ￥</span></el-col>
      <el-col style="padding: 15px 15px" :span="6" :offset="0">总入账：<span class="data">{{ data.totalIncome ?
        data.totalIncome : 0 }} ￥</span></el-col>
      <el-col style="padding: 15px 15px" :span="6" :offset="0">总分润：<span class="data">{{ data.totalFenRun ?
        data.totalFenRun : 0 }} ￥</span></el-col>
    </el-row>
    <!-- 卡片视图 -->
    <!-- 用户列表区域 -->
    <!--<el-card>
      
       <el-table
        :data="tableData"
        border
        fit
        :height="height"
        highlight-current-row
        style="width: 100%"
        @sort-change="sortChange"
      >
        <el-table-column label="编号" type="index" width="50">
        </el-table-column>
        <el-table-column label="设备ID" align="center" prop="deviceId">
          <template slot-scope="scope">
            <span>{{ scope.row.deviceId }}</span>
          </template>
        </el-table-column>
        <el-table-column label="设备状态" align="center" prop="deviceStatus">
          <template slot-scope="scope">
            <span v-if="scope.row.deviceStatus == '0'">关锁</span>
            <span v-if="scope.row.deviceStatus == '1'">开锁</span>
            <span v-if="scope.row.deviceStatus == '99'">未知</span>
          </template>
        </el-table-column>
        <el-table-column
          label="电子围栏名称"
          align="center"
          prop="electronicFenceName"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.electronicFenceName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="运营商名称" align="center" prop="operatorName">
          <template slot-scope="scope">
            <span>{{ scope.row.operatorName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="经度" align="center" prop="longitude">
          <template slot-scope="scope">
            <span>{{ scope.row.longitude }}</span>
          </template>
        </el-table-column>
        <el-table-column label="纬度" align="center" prop="latitude">
          <template slot-scope="scope">
            <span>{{ scope.row.latitude }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="管理员名称"
          align="center"
          prop="administratorName"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.administratorName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="今日收入" align="center" prop="totalIncome">
          <template slot-scope="scope">
            <span>{{ scope.row.totalIncome || "0" }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="footerBox">

        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div> 
    </el-card>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      height: window.innerHeight - 256, //表格高度
      value: "",
      tableData: [],
      loading: true,
      id: "",
      searchInput: "",
      payInfoTime: "",
      // 获取用户列表查询参数对象
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 10,
        //查询条件
        condition: {},
      },
      userlist: [],
      total: 0,
      // 修改用户
      editDialogVisible: false,
      editUserForm: {},
      // 分配角色对话框
      setRoleDialogVisible: false,
      data: {},
    };
  },
  created() {
    // this.queryInfo.page=this.$route.query.page;
    //  this.queryInfo.limit=this.$route.query.limit;
    // let date = new Date();
    // let y = date.getFullYear();
    // let m = date.getMonth() + 1;
    // m = m < 10 ? "0" + m : m;
    // let d = date.getDate();
    // d = d < 10 ? "0" + d : d;
    // this.queryInfo.condition.payInfoTime = y + "-" + m + "-" + d;
    // this.payInfoTime = this.queryInfo.condition.payInfoTime;
    // this.getListData();
    window.addEventListener("resize", this.getHeight);
    this.getData();
  },
  mounted() {
    this.loading = false;
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeight);
  },
  watch: {
    payInfoTime(newV) {
      if (newV) {
        this.queryInfo.condition.startDt = newV[0];
        this.queryInfo.condition.endDt = newV[1];
      } else {
        this.queryInfo.condition.startDt = "";
        this.queryInfo.condition.endDt = "";
      }
    },
  },
  methods: {
    // 获取数据报表
    getData() {
      this.$http
        .post("/electronicFence/dataManage", this.queryInfo.condition)
        .then((res) => {
          if (res.data.code == 200) {
            this.data = res.data.data;
          }
        });
    },
    // 选择时间
    dateChange(e) {
      console.log(e);
      let date = new Date(e);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      this.payInfoTime = y + "-" + m + "-" + d;
      // console.log(date.getFullYear());
    },
    //条件查询
    search() {
      this.getData();
    },

    //状态改变事件
    // changeStatus(row) {

    //   var that=this;
    // //   alert()
    //  that.repairsStatus=1;
    //   that.$http.post("/repairsInfo/save", {repairsStatus:1,id:row.id}
    //   ).then(function (response) {
    //       that.$notify.success({
    //         title: "提示",
    //         message: "保存成功",
    //         showClose: true,
    //       });
    //     that.getListData();
    //   }).catch(function(error){
    //     //   console.log("---")
    //       that.$notify.info({
    //         title: "提示",
    //         message: "保存失败",
    //         showClose: true,
    //       });
    //   })

    // },
    judgeStatus(orderStatus) {
      if (orderStatus === 0) {
        return "未完成";
      } else if (orderStatus === 1) {
        return "未支付";
      } else if (orderStatus === 2) {
        return "已完成";
      } else {
        return "";
      }
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      // this.getListData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      // this.getListData();
    },

    // 监听排序
    sortChange() { },
    //跳转到详情
    gotoDetail(id) {
      this.$router.push({
        path: "/financeMag/applyFeeDetail",
        query: { id: id },
      });
    },

    // 删除用户
    // async del(id) {
    //   const confirmResult = await this.$confirm("确定删除吗?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   }).catch((err) => err);
    //   if (confirmResult === "confirm") {
    //        var that = this;
    //     that.$http.post("/repairsInfo/delete" ,{"id":id}).then(function (response) {
    //       that.$notify.success({
    //         title: "提示",
    //         message: "删除成功",
    //         showClose: true,
    //       });
    //       that.getListData();
    //     });
    //   }
    // },
    getListData() {
      // 获取列表
      var that = this;
      var data = that.queryInfo;
      //   console.log(data)
      that.$http
        .post("/device/stasticByDay", that.queryInfo)
        .then(function (response) {
          //   console.log("------")
          // console.log(response);
          if (response.data.code == 200) {
            // console.log("------")
            // console.log(response.data.data.data)
            that.total = response.data.data.count;
            //   that.queryInfo.page=response.data.page
            that.tableData = response.data.data.data;
          }
        });
    },
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - 256;
    },
  },
};
</script>

<style lang="less" scoped>
.textblue {
  color: #008eff;
}

.managingPeople {
  padding: 20px;

  .el-card {
    height: calc(100vh - 114px);
    position: relative;

    .el-select>.el-input {
      width: 200px;
    }

    // .header-search-box .header-search-input {
    //     width: 325px;
    // }
    input::placeholder {
      font-size: 12px;
    }

    .handle-btn .el-button.add-column-btn {
      width: 100%;
      font-size: 13px;
      background-color: transparent;
      color: #008eff;
      z-index: 102;
    }

    .footerBox {
      position: absolute;
      width: 100%;
      height: 78px;
      left: 20px;
      right: 20px;
      bottom: 0;
    }
  }
}

/deep/ .el-range-editor--small .el-range__icon {
  line-height: 24px !important;
}

.data-box {
  font-size: 16px;
  font-weight: bold;

  .data {
    font-size: 20px;
    font-weight: bolder;
  }
}

.header-search-box {
  .textblue {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
  }

  .el-input__inner {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
